<template>
	<!-- 入驻审核 -->
	<div class="enterAudit el-content">
		<a-tabs v-model:activeKey="apply_status" @change="getStoreList(1)">
		    <a-tab-pane key="1" tab="待审核"></a-tab-pane>
		    <a-tab-pane key="3" tab="通过"></a-tab-pane>
		    <a-tab-pane key="2" tab="未通过"></a-tab-pane>
		</a-tabs>
		<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'商户LOGO',dataIndex:'logo',slots:{customRender:'logo'}},
			{title:'商户名称',dataIndex:'name'},
			{title:'用户',dataIndex:'user.nickname'},
			{title:'联系人',dataIndex:'mobile'},
			{title:'申请时间',dataIndex:'update_time'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]">
			<template #logo="{record}">
				<a-avatar :src="record.logo" />
			</template>
			<template #action="{record}">
				<a-space>
					<router-link :to="{path:'/adhibition/store/store_audit',query:{id:record.id}}">
						<kd-button type="primary" 
							title="详情" 
							icon="ri-more-fill" 
							v-has="{action:'/adhibition/store/store_audit'}">
						</kd-button>
					</router-link>
					<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_marketing_store_check_del'}" @click="deleteStore(record.id)"></kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getStoreList(info.page,e)}"
				@change="(e)=>{getStoreList(e,info.limit)}"
			/>
		</div>
	</div>
</template>
<script>
import { reactive, toRefs } from 'vue';
import shopModel from '@/api/addons/shop.js'
import common from '@/api/common.js'
export default{
	setup(){
		const _d = reactive({
			apply_status:'1',
			info:{
				list:[],
				page:1,
				limit:10,
				count:0,
			},
		})
		getStoreList(1,_d.info.limit)
		function getStoreList(page,limit){
			shopModel.getShopApply(page,limit,{apply_status:_d.apply_status},res=>_d.info = {limit,...res})
		}

		const deleteStore = (id)=>common.deleteDataList(id,54,"确认要删除该商户申请吗？").then(()=>{
			getStoreList(_d.info.page,_d.info.limit)
		})

		return{
			...toRefs(_d),
			getStoreList,
			deleteStore
		}
	}
}
</script>

<style>
</style>
